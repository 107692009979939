<template>
  <div class="header">
    <div class="image">
      <ProfilePhoto :src="portfolio.me.photo" />
    </div>

    <div class="text">
      <h1>{{ portfolio.me.name }}</h1>
      <p class="about">{{ portfolio.me.about }}</p>

      <div class="location">
        <font-awesome-icon icon="map-marker-alt" />
        {{ portfolio.me.location }}
      </div>
    </div>

    <div class="contact">
      <a :href="portfolio.me.social.linkedin" target="_blank">
        <font-awesome-icon :icon="['fab', 'linkedin']" />
      </a>

      <a :href="portfolio.me.social.github" target="_blank">
        <font-awesome-icon :icon="['fab', 'github']" />
      </a>

      <a :href="`mailto:${portfolio.me.contact.email}`" target="_blank">
        <font-awesome-icon icon="envelope" />
      </a>

      <!-- <a :href="portfolio.me.social.medium" target="_blank">
        <font-awesome-icon :icon="['fab', 'medium']" />
      </a>

      <a :href="portfolio.me.social.stackExchange" target="_blank">
        <font-awesome-icon :icon="['fab', 'stack-exchange']" />
      </a> -->
    </div>
  </div>
</template>

<style lang="less" scoped>
h1 {
  padding: 1rem;
  font-weight: 700;
}

.about {
  max-width: 32rem;
}

.header {
  padding: 1em 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blue-background);
  color: var(--white);
}

.image {
  width: 100%;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  margin-bottom: 1em;
  text-align: center;
  max-width: 40em;
}

.location {
  margin: 2rem 0 1rem;
  color: rgba(255, 255, 255, 0.75);

  > * {
    margin-right: 0.25rem;
  }
}

.contact {
  margin: 0 0 1rem;
  color: rgba(255, 255, 255, 0.5);

  > a:hover {
    color: rgba(255, 255, 255, 1);
  }

  > * {
    font-size: 1.75rem;

    + * {
      margin-left: 1.25rem;
    }
  }
}

.links {
  margin: 2rem 0 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .link {
    padding: 0.75em 1em;
    text-align: center;

    &.current {
      color: var(--blue-background);
      background-color: var(--white);
      border-radius: 0.5em 0.5em 0 0;
    }
  }
}
</style>

<script lang="ts">
import ProfilePhoto from "@/components/ProfilePhoto.vue";
import { Portfolio } from "@/types/Portfolio";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    ProfilePhoto,
  },
  props: {
    portfolio: {
      type: Object as PropType<Portfolio>,
    },
  },
});
</script>
